<template>
  <div>
    <input type="text" v-model="yyyymmdd" />
    <input type="text" v-model="rangeSimpleParams.sdate" />
    <input type="text" v-model="rangeSimpleParams.edate" />
    <span class="float_right">
      <input type="checkbox" v-model="attrs.readonly" id="cccc1" />
      <label for="cccc1"><span></span>readonly</label>
    </span>
    <input type="text" v-model="rangeParams.sdate" />
    <input type="text" v-model="rangeParams.edate" />
    {{attrs}}
    <div class="booking_pop_wrap">
      <table class="tbl_row">
        <colgroup>
          <col width="140">
          <col width="140">
          <col width="350">
          <col width="*">
        </colgroup>
        <tbody>
          <tr>
            <th>yyyymmdd</th>
            <th>yyyymm</th>
            <th>Date Picker Range Simple</th>
            <th>Date Picker Range</th>
          </tr>
          <tr>
            <td>
              <e-date-picker v-model="yyyymmdd" v-bind="attrs" />
            </td>
            <td>
              <e-month-picker v-model="yyyymmdd" v-bind="attrs" />
            </td>
            <td>

              <!-- [s] Date Picker Range Simple -->
              <e-date-range-picker-simple
                :sdate="rangeSimpleParams.sdate"
                :edate="rangeSimpleParams.edate"
                :is-debug="true"
                v-bind="attrs"
                @change="changeDateRangeSimple"
              /><!-- is-debug 옵션은 false 로 셋팅할것 -->

              <div class="sample">
                <p>{{ rangeSimpleParams.sdate }} ~ {{ rangeSimpleParams.edate }}</p>
                <p>컴포넌트 호출 페이지에서 값 변경시</p>
                <input type="text" v-model="tempSdt1" style="width:120px;" placeholder="시작일(yyyyMMdd)" />
                <input type="text" v-model="tempEdt1" style="width:120px;" placeholder="종료일(yyyyMMdd)"/>
                &nbsp;
                <button class="button gray sm" @click.prevent="apply('01')">적용</button>
              </div>
              <!-- [e] Date Picker Range Simple-->

            </td>
            <td>

              <!-- [s] Date Picker Range -->
              <e-date-range-picker
                title="기간 검색"
                :sdate="rangeParams.sdate"
                :edate="rangeParams.edate"
                :is-debug="true"
                @change="changeDateRange"
              /><!-- is-debug 옵션은 false 로 셋팅할것 -->

              <div class="sample">
                <p>{{ rangeParams.sdate }} ~ {{ rangeParams.edate }}</p>
                <p>컴포넌트 호출 페이지에서 값 변경시</p>
                <input type="text" v-model="tempSdt2" style="width:120px;" placeholder="시작일(yyyyMMdd)" />
                <input type="text" v-model="tempEdt2" style="width:120px;" placeholder="종료일(yyyyMMdd)"/>
                &nbsp;
                <button class="button gray sm" @click.prevent="apply('02')">적용</button>
              </div>
              <!-- [e] Date Picker Range -->

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default ({
  name: 'SampleDatePicker',

  data () {
    return {
      rangeSimpleParams: {
        sdate: '20211111',
        edate: ''
      },
      rangeParams: {
        sdate: '20211111',
        edate: ''
      },
      attrs: {
        readonly: false
      },
      yyyymmdd: '20211122',
      yyyymm: '202111',
      tempSdt1: '20211001',
      tempEdt1: '20211015',
      tempSdt2: '20211001',
      tempEdt2: '20211015'
    }
  },

  watch: {
    yyyymmdd () {
      console.log('yyyymmdd : ' + this.yyyymmdd)
    },
    yyyymm () {
      console.log('yyyymm : ' + this.yyyymm)
    }
  },

  components: {
    EDatePicker: () => import('@/components/common/EDatePicker'),
    EMonthPicker: () => import('@/components/common/EMonthPicker'),
    EDateRangePicker: () => import('@/components/common/EDateRangePicker'),
    EDateRangePickerSimple: () => import('@/components/common/EDateRangePickerSimple')
  },

  methods: {
    apply (type) {
      if (type === '01') {
        this.rangeSimpleParams.sdate = this.tempSdt1
        this.rangeSimpleParams.edate = this.tempEdt1
      } else if (type === '02') {
        this.rangeParams.sdate = this.tempSdt2
        this.rangeParams.edate = this.tempEdt2
      }
    },
    changeDateRangeSimple (sdt, edt) {
      this.rangeSimpleParams.sdate = sdt
      this.rangeSimpleParams.edate = edt
    },
    /**
     * Date Range Picker 변경시 호출
     */
    changeDateRange (sdt, edt) {
      this.rangeParams.sdate = sdt
      this.rangeParams.edate = edt
    }
  }
})
</script>

<style scoped>
.sample {
  margin-top:20px;
  padding:5px;
  border: 1px dotted #d8d8d8;
}
.sample p {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
